import type {Insight} from "../../models/api/insight/Insight";
import {insights as insights20240612} from "./2024/20240612";
import {insights as insights20240808} from "./2024/20240808";
import {insights as insights20240827} from "./2024/20240827";
import {insights as insights20240904} from "./2024/20240904";

const insights: Array<Insight> = [
    ...insights20240904,
    ...insights20240827,
    ...insights20240808,
    ...insights20240612,
];

export {
    insights,
};
